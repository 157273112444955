import { AutocompleteInput, ReferenceInput } from "react-admin"

const SPVRefInput = () => {
  const filterToQuery = (searchText: string) => ({ search: searchText })
  return (
    <ReferenceInput filter={{ type: "spv" }} reference="companies" source="spv_id" label="SPV">
      <AutocompleteInput
        filterToQuery={filterToQuery}
        label="SPV"
        optionText="name"
        debounce={500}
      />
    </ReferenceInput>
  )
}

export { SPVRefInput }
