import PropertyIcon from "@mui/icons-material/House"
import { ContractorCreate, ContractorEdit, ContractorList, ContractorShow } from "./contractors"

const contractorsResource = {
  icon: PropertyIcon,
  list: ContractorList,
  show: ContractorShow,
  create: ContractorCreate,
  edit: ContractorEdit,
}

export default contractorsResource
