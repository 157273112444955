import { UserRefField } from "resources/iam/users"
import appConfig from "../../../config/app.config"
import {
  Create,
  Edit,
  required,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextInput,
  TextField,
  TabbedShowLayoutTabs,
  Show,
  Datagrid,
  DateField,
  EditButton,
  List,
  ShowButton,
  PaginationProps,
  Pagination,
  NumberField,
  EmailField,
  UrlField,
  ReferenceManyField,
  BooleanField,
  DeleteButton,
} from "react-admin"
import { ContractorRefField } from "./ContractorRefField"
import { PropertyRefField } from "../properties"
import { useLocation } from "react-router-dom"
import { AddPropertyToContractorButton } from "../properties/AddPropertyToContractorButton"

interface ContractorProps {
  name: string
  address_field_1?: string
  address_field_2?: string
  address_city?: string
  address_postal_code?: string
  address_country?: string
  website?: string
  email?: string
  tax_number?: string
}

interface ContractorFormProps extends ContractorProps {
  isExisting?: boolean
}

const ContractorForm = (_props: ContractorFormProps) => {
  return (
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="address_field_1" />
      <TextInput source="address_field_2" />
      <TextInput source="address_city" />
      <TextInput source="address_postal_code" />
      <TextInput source="address_country" />
      <TextInput source="website" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="tax_number" />
    </SimpleForm>
  )
}

export const ContractorCreate = (props: ContractorProps) => {
  return (
    <Create {...props} redirect="list">
      <ContractorForm {...props} />
    </Create>
  )
}

export const ContractorEdit = (props: ContractorProps) => {
  const propsWithIsExisting = { ...props, isExisting: true }
  return (
    <Edit mutationMode="pessimistic">
      <ContractorForm {...propsWithIsExisting} />
    </Edit>
  )
}

export const ContractorShow = (props: ContractorProps) => {
  const router = useLocation()
  return (
    <Show title="Contractors">
      <TabbedShowLayout
        spacing={2}
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
      >
        <Tab label="Contractor Details">
          <TextField source="name" />
          <TextField source="address_field_1" />
          <TextField source="address_field_2" />
          <TextField source="address_city" />
          <TextField source="address_postal_code" />
          <TextField source="address_country" />
          <UrlField source="website" />
          <EmailField source="email" />
          <TextField source="phone" />
          <TextField source="tax_number" />
        </Tab>

        <Tab label="Properties">
          <AddPropertyToContractorButton />
          <ReferenceManyField reference="property_contractors" target="contractor_id" label={false}>
            <Datagrid bulkActionButtons={false}>
              <ContractorRefField source="contractor_id" />
              <PropertyRefField source="property_id" />
              <BooleanField source="is_emergency_contact" />
              <UserRefField source="created_by" />
              <DateField source="created_at" />

              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
          <UserRefField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const ContractorPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const ContractorList = () => {
  return (
    <List
      perPage={appConfig.paginationOptions[0]}
      exporter={false}
      sort={{ field: "name", order: "DESC" }}
      pagination={<ContractorPagination />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <TextField source="tax_number" />
        <TextField source="address_field_1" />
        <TextField source="address_city" label="City" />
        <TextField source="address_country" label="Country" />
        <TextField source="address_postal_code" label="Postal code" />
        <TextField source="email" />
        <TextField source="phone" />
        <DateField source="created_at" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}
