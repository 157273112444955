import React from "react"
import { FunctionField, ReferenceField, ReferenceFieldProps } from "react-admin"

interface Props {
  source?: string
}

interface IContractor {
  name: string
}

const ContractorRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    label="Contractors"
    source="contractor_id"
    reference="contractors"
    link="show"
    {...props}
  >
    <FunctionField render={(record: IContractor) => (record.name ? record.name : "")} />
  </ReferenceField>
)

ContractorRefField.defaultProps = {
  source: "contractor_id",
}

export { ContractorRefField }
