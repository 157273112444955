import { omitBy } from "lodash"
import queryString from "query-string"
import { DataProvider, fetchUtils } from "react-admin"

import { countDiff } from "../utils"

const sortOrderFormatter = (field: string, order: string) => {
  if (order === "ASC") return `${field},asc`
  if (order === "DESC") return `${field},desc`
  throw new Error("Incorrect order type")
}

export const standardRestApiDataProviderFactory = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
): DataProvider => {
  const getBaseUrl = () => {
    return apiUrl
  }

  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination

      const pagination = {
        limit: perPage,
        offset: (page - 1) * perPage,
      }

      const sort = [sortOrderFormatter(params.sort.field, params.sort.order)]

      let queryParams = params.filter
      queryParams = {
        ...queryParams,
        ...pagination,
        sort,
      }
      const query = queryString.stringify(queryParams, { arrayFormat: "comma" })
      const url = `${getBaseUrl()}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({
        data: json.data,
        total: json.meta.total,
      }))
    },

    getOne: (resource, params) =>
      httpClient(`${getBaseUrl()}/${resource}/${params.id}`).then(({ json }) => ({
        data: json,
      })),

    getMany: (resource, params: any) => {
      const value = params.ids.length && params.ids[0]?.length ? params.ids : ""
      let query = ""
      if (value) {
        let queryParams = params.filter
        queryParams = {
          id: value,
        }
        query = queryString.stringify(queryParams, { arrayFormat: "comma" })
      }

      const url = `${getBaseUrl()}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({ data: json.data }))
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination

      const pagination = {
        limit: perPage,
        offset: (page - 1) * perPage,
      }

      const sort = [sortOrderFormatter(params.sort.field, params.sort.order)]

      let queryParams = params.filter
      queryParams = {
        ...queryParams,
        ...pagination,
        sort,

        [params.target]: params.id,
      }

      const query = queryString.stringify(queryParams, { arrayFormat: "comma" })

      const url = `${getBaseUrl()}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({
        data: json.data,
        total: json.meta.total,
      }))
    },

    update: (resource, params) => {
      const fieldsAlwaysToSent = ["discount_amount", "discount_percentage"]
      // no need to send all fields, only updated fields are enough
      let data = countDiff(params.data, params.previousData, fieldsAlwaysToSent)

      return httpClient(`${getBaseUrl()}/${resource}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      }).then(({ json }) => ({ data: json }))
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${getBaseUrl()}/${resource}/${id}`, {
            method: "PATCH",
            body: JSON.stringify(params.data),
          }),
        ),
      ).then((responses) => ({
        data: responses.map(({ json }) => json),
      })),

    create: (resource, params) => {
      const data = omitBy(params.data, (value) => value === "")

      return httpClient(`${getBaseUrl()}/${resource}`, {
        method: "POST",
        body: JSON.stringify(data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id } as any,
      }))
    },

    delete: (resource, params) =>
      httpClient(`${getBaseUrl()}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: { ...json, id: params.id } })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${getBaseUrl()}/${resource}/${id}`, {
            method: "DELETE",
          }),
        ),
      ).then((responses) => ({ data: responses.map(({ json }) => json) })),
  }
}
