import {
  BooleanInput,
  Create,
  Identifier,
  RaRecord,
  RedirectionSideEffect,
  required,
  SimpleForm,
} from "react-admin"

import { PropertyRefInput } from "resources/real_estate/properties"
import { ContractorRefInput } from "../contractors/ContractorRefInput"

const redirect: RedirectionSideEffect = (
  _resource?: string,
  _id?: Identifier,
  data?: Partial<RaRecord>,
) => (data?.property_id ? `properties/${data.property_id}/show/contractors` : "show")

interface PropertyContractorCreateProps {
  property_id?: string
  contractor_id?: string
}

export const PropertyContractorCreate = (props: PropertyContractorCreateProps) => {
  return (
    <Create {...props} redirect={redirect}>
      <SimpleForm>
        <PropertyRefInput source="property_id" validate={[required()]} />
        <ContractorRefInput source="contractor_id" validate={[required()]} />
        <BooleanInput source="is_emergency_contact" />
      </SimpleForm>
    </Create>
  )
}
