import { AutocompleteInputProps } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const ContractorRefInput = (props: Props) => {
  return (
    <AutocompleteInputLocal
      perPage={450}
      source="contractor_id"
      {...props}
      optionText="name"
      resource="contractors"
      label="Contractor"
      sort={{ field: "created_at", order: "DESC" }}
    />
  )
}

export { ContractorRefInput }
